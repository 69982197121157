import Vue from 'vue';
import Component from 'vue-class-component';
import { State as StateClass } from 'vuex-class';
import { State } from '@/store/models';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import LogoAlt from '@/modules/landing/components/logo-alt/LogoAlt.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import NavbarAlt from '@/modules/landing/components/navbar-alt/NavbarAlt.vue';
import LandingInvesting from '@/modules/landing/components/investing/LandingInvesting.vue';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    BannerLegal,
    LogoAlt,
    NavbarAlt,
    LandingInvesting,
  },
})

export default class LandingHeader extends Vue {
  isFixed = false;
  @StateClass assets!: State['assets'];

  $: (e: string) => any = (): null => null;
  check: () => void = (): void => {};
  tag: string | null = null;
  threshold: number = 1000;

  mounted(): void {
    this.$ = (e: string): any => document.querySelector(e);
    this.tag = this.getTargetTag();
    this.main();
  }

  getTargetTag(): string {
    if (navigator.userAgent.includes('Edge')) {
      return 'body';
    }
    if (navigator.userAgent.includes('Firefox/') && ((+navigator.userAgent.split('Firefox/')[1]) >= 62)) {
      return 'html';
    }
    if (!(window as any).chrome
      && 'WebkitAppearance' in (document.documentElement as any).style) {
      return 'body';
    }
    return 'html';
  }

  registerEvent(): void {
    window.addEventListener('scroll', this.check);
  }

  removeEvent(): void {
    window.removeEventListener('scroll', this.check);
  }

  main(): void{
    this.check = (): void => {
      const { $, tag, threshold } = this;
      if (!tag) {
        return;
      }
      this.isFixed = $(tag).scrollTop > threshold;
    };
    this.registerEvent();
  }

  beforeDestroy(): void {
    this.removeEvent();
  }

  get asset(): Asset {
    return this.assets[0];
  }
}
