import Vue from 'vue';
import Component from 'vue-class-component';
// @ts-ignore
import VueWaypoint from 'vue-waypoint';
// eslint-disable-next-line import/extensions
import Graph from '@/assets/illustrations/property-vs-aex.svg?inline';

// Waypoint plugin
// @see https://github.com/scaccogatto/vue-waypoint
Vue.use(VueWaypoint);

@Component({
  components: {
    Graph,
  },
})

export default class LandingStock extends Vue {
  animationFired: boolean = false;

  /**
   * Set Waypoint config.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   */
  intersectionOptions: object = {
    root: null,
    thresholds: [0],
    rootMargin: '32px 0px 0px 0px',
  };

  /**
   * Handle element becoming visible in viewport.
   *
   * @see https://github.com/scaccogatto/vue-waypoint
   * @return void
   */
  onWaypoint({ going, direction }): void {
    if (!this.animationFired
      && going === VueWaypoint.map.GOING_IN
      && direction === VueWaypoint.map.DIRECTION_TOP) {
      this.animationFired = true;
    }
  }
}
