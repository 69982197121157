import { Vue, Component, Prop } from 'vue-property-decorator';
import { logoalt } from '../../../../../whitelabel.config';

const LogoAltImage = require(`../../../../assets/logos/${logoalt}?inline`).default;

@Component({
  components: {
    LogoAltImage,
  },
})

export default class LogoAlt extends Vue {
  classes: string[] = ['logo'];

  @Prop({ default: 'medium' })
  size!: string;

  /**
   * Computed property to get
   * the classes of the logo.
   *
   * @return string
   */
  get logoClasses(): string {
    this.classes.push(`logo--${this.size}`);

    return this.classes.join(' ');
  }
}
