import Vue from 'vue';
import Component from 'vue-class-component';
import CounterItem from '@/components/common/counter/CounterItem.vue';

@Component({
  components: {
    CounterItem,
  },
})

export default class LandingCounters extends Vue {

}
