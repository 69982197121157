import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LandingInvestingItem extends Vue {
  @Prop({ default: 'default' })
  variant!: string;

  @Prop({ default: 0 })
  number!: number;

  @Prop({ default: 'Lorem ipsum dolor sit amet.' })
  copy!: string;
}
