import Vue from 'vue';
import Component from 'vue-class-component';
import BackToTop from '@/components/common/back-to-top/BackToTop.component.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import LandingHeader from '@/modules/landing/components/header/LandingHeader.vue';
import LandingAccessible from '@/modules/landing/components/accessible/LandingAccessible.vue';
import LandingBenefits from '@/modules/landing/components/benefits/LandingBenefits.vue';
import LandingStock from '@/modules/landing/components/stock/LandingStock.vue';
import LandingFund from '@/modules/landing/components/fund/LandingFund.vue';
import LandingCounters from '@/modules/landing/components/counters/LandingCounters.vue';
import LandingSafeguards from '@/modules/landing/components/safeguards/LandingSafeguards.vue';
import LandingRisks from '@/modules/landing/components/risks/LandingRisks.vue';
import LandingFooter from '@/modules/landing/components/footer/LandingFooter.vue';

@Component({
  components: {
    BackToTop,
    BannerLegal,
    LandingHeader,
    LandingAccessible,
    LandingBenefits,
    LandingStock,
    LandingFund,
    LandingCounters,
    LandingSafeguards,
    LandingRisks,
    LandingFooter,
  },
})

export default class Landing extends Vue {
}
