import Vue from 'vue';
import Component from 'vue-class-component';
import { State as StateClass } from 'vuex-class';
import { State } from '@/store/models';
import LandingBenefitsItem from '@/modules/landing/components/benefits/item/LandingBenefitsItem.vue';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    LandingBenefitsItem,
  },
})

export default class LandingBenefits extends Vue {
  @StateClass assets!: State['assets'];

  get asset(): Asset {
    return this.assets[0];
  }
}
