
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { intercom } from '../../../../whitelabel.config';

const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);

@Component
export default class BackToTop extends Vue {
  currentOffset: number = window.pageYOffset;
  intercom = intercom;

  @Prop({ default: 'Back to top' })
  label!: string;

  @Prop({ default: 600 })
  offsetThreshold!: number;

  /**
   * Mounted lifecycle hook.
   */
  mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  /**
   * Destroyed lifecycle hook.
   */
  destroyed(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Set offset.
   *
   * @return void
   */
  setCurrentOffset(offset: number): void {
    this.currentOffset = offset;
  }

  /**
   * Catch window scroll event.
   *
   * @return void
   */
  handleScroll(): void {
    this.setCurrentOffset(window.pageYOffset);
  }

  /**
   * Computed property to toggle button.
   */
  get showGoTopButton(): boolean {
    return this.currentOffset > this.offsetThreshold;
  }
}
