import Vue from 'vue';
import Component from 'vue-class-component';
import LandingInvestingItem from '@/modules/landing/components/investing/item/LandingInvestingItem.vue';

@Component({
  components: {
    LandingInvestingItem,
  },
})

export default class LandingInvesting extends Vue {

}
