import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Action, Getter } from 'vuex-class';
import { State } from '@/store/models';
import Loader from '@/components/common/loader/Loader.vue';
import CounterItem from '@/components/common/counter/CounterItem.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import LandingGallery from '@/modules/landing/components/fund/gallery/LandingGallery.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import { DownloadPath } from '@/store/modules/downloads/downloads';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    Loader,
    CounterItem,
    WithDownloads,
    LandingGallery,
    FundDocs,
  },
})

export default class LandingFund extends Vue {
  @StateClass assets!: State['assets'];

  @Action getDownloads!: Function;

  @Getter getDownloadsByPath!: Function;
  @Getter isDownloadsProcessing!: Function;

  @Watch('asset')
  onAssetChange(newAsset: Asset, oldAsset: undefined | Asset): void {
    if (!this.images && newAsset && (!oldAsset || oldAsset.id !== newAsset.id) && !this.isDownloadsProcessing(this.dlPath)) {
      this.getDownloads(this.dlPath);
    }
  }

  mounted(): void {
    if (!!this.dlPath && !this.images && !this.isDownloadsProcessing(this.dlPath)) {
      this.getDownloads(this.dlPath);
    }
  }

  /**
   * Get current asset.
   */
  get asset(): Asset | undefined {
    return this.assets[0];
  }

  get dlPath(): DownloadPath | undefined {
    return this.asset ? { collection: 'assets', id: this.asset.id, properties: 'images' } : undefined;
  }

  get images(): [string, string][] | undefined {
    return this.dlPath && this.getDownloadsByPath(this.dlPath);
  }

  get assetAddress(): string {
    if (!this.asset) {
      return '';
    }

    return this.asset.street && this.asset.houseNumber && this.asset.postalCode && this.asset.city
      ? `${this.asset.street} ${this.asset.houseNumber}, ${this.asset.postalCode}, ${this.asset.city}`
      : this.asset.city;
  }
}
