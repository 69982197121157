import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';

@Component({
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    LazyImage,
  },
})
export default class PropertyGallery extends Vue {
  @Prop({ default: (): [string, string][] => [['name', '../../../assets/images/property/thumbs/slide1.png']] })
  images!: string[];

  slideTo(number: number): void {
    // @ts-ignore
    this.$refs.gallery.slideTo(number);
  }

  @Watch('images.length')
  onImagesLengthChange(newLength: number, oldLength: number): void {
    if (newLength !== oldLength && this.$refs.gallery) {
      // @ts-ignore
      this.$refs.gallery.restart();
    }
  }
}
