import { Vue, Component } from 'vue-property-decorator';
import { Language } from '@/i18n';

import { requireLegalBanner } from '../../../../whitelabel.config';

@Component({})

export default class BannerLegal extends Vue {
  requireLegalBanner = requireLegalBanner;

  get imgURL(): any {
    const imageNL = require('@/assets/images/bannerLegal/banner-legal.nl.jpg');
    const imageEN = require('@/assets/images/bannerLegal/banner-legal.en.jpg');
    switch ((this.$i18n.locale as Language)) {
      case 'nl':
        return imageNL;
      default: return imageEN;
    }
  }
}
