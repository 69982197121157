import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LandingBenefitsItem extends Vue {
  @Prop({ default: 'default' })
  variant!: string;

  @Prop({ default: 'Title' })
  title!: string;

  @Prop({ default: 'Lorem ipsum dolor sit amet.' })
  copy!: string;
}
