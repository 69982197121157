import { Vue, Component, Prop } from 'vue-property-decorator';
// @ts-ignore
import VueWaypoint from 'vue-waypoint';
// @ts-ignore
import VueAnimateNumber from 'vue-animate-number';
import { formatNumber } from '@/filters/number';

// Waypoint plugin
// @see https://github.com/scaccogatto/vue-waypoint
Vue.use(VueWaypoint);

// Animate number plugins
// @see https://github.com/wangdahoo/vue-animate-number
Vue.use(VueAnimateNumber);

@Component
export default class CounterItem extends Vue {
  animationFired: boolean = false;

  @Prop({ default: 'default' })
  variant!: string;

  @Prop({ default: 'Title' })
  title!: string;

  @Prop({ default: '%' })
  unit!: string;

  @Prop({ default: 5000 })
  duration!: number;

  @Prop({ default: 0 })
  from!: number;

  @Prop({ default: 100 })
  to!: number;

  @Prop({ default: 0 })
  digits!: number;

  /**
   * Set Waypoint config.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   */
  intersectionOptions: object = {
    root: null,
    thresholds: [0],
    rootMargin: '32px 0px 0px 0px',
  };

  /**
   * Handle element becoming visible in viewport.
   *
   * @see https://github.com/scaccogatto/vue-waypoint
   * @return void
   */
  onWaypoint({ going, direction }): void {
    if (!this.animationFired && going === VueWaypoint.map.GOING_IN) {
      this.animationFired = true;
      this.animate();
    }
  }

  /**
   * Execute animation.
   */
  animate(): void {
    (this.$refs[`counter-item__number__animator--${this.variant}`] as any).start();
  }

  /**
   * VueAnimateNumber number format function.
   *
   * @see https://github.com/wangdahoo/vue-animate-number
   */
  formatter(num: number): number {
    return parseFloat(formatNumber(num, this.digits));
  }
}
