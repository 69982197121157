import Vue from 'vue';
import Component from 'vue-class-component';
import LandingSafeguardsItem from '@/modules/landing/components/safeguards/item/LandingSafeguardsItem.vue';

@Component({
  components: {
    LandingSafeguardsItem,
  },
})

export default class LandingSafeguards extends Vue {

}
