import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LandingSafeguardsItem extends Vue {
  @Prop({ default: 'Investment' })
  title!: string;

  @Prop({ default: 'Safeguard' })
  text!: string;
}
