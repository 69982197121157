import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { Slide } from 'vue-burger-menu';
import { State } from '@/store/models';
import LogoAlt from '@/modules/landing/components/logo-alt/LogoAlt.vue';
import NavbarAltLinks from '@/modules/landing/components/navbar-alt/links/NavbarAltLinks.vue';
import DropdownMenu from '@/components/common/navbar/dropdown/DropdownMenu.vue';
import LanguagesAccount from '@/components/common/navbar/languages-account/button/LanguagesAccount.vue';
import ResponsiveMenu from '@/components/common/navbar/responsive/ResponsiveMenu.vue';
import ResponsiveLinks from '@/components/common/navbar/responsive/links/ResponsiveLinks.vue';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';

@Component({
  components: {
    Slide,
    LogoAlt,
    NavbarAltLinks,
    DropdownMenu,
    ResponsiveLinks,
    LanguagesAccount,
    ResponsiveMenu,
  },
})

export default class Navbar extends Vue {
  @StateClass user!: State['user'];
  @Getter isModalOpen!: boolean;
  @Getter isUserLoggedIn!: boolean;
  @Action openModal!: Function;

  isSliderOpen= false;

  @Watch('isModalOpen')
  onAuthActiveChange(isModalOpen): void {
    if (isModalOpen) {
      this.isSliderOpen = true;
      setTimeout((): void => {
        this.isSliderOpen = false;
      }, 150);
    }
  }

  get brand(): string {
    return require('../../../../../whitelabel.config').name;
  }

  /* get myBrand(): string {
    return `${capitalize(this.$t('common.my') as string)} ${capitalize(this.brand)}`;
  } */

  get isLanding(): boolean {
    return this.$route.fullPath.startsWith('/landing');
  }

  get isAuthPage(): boolean {
    return isAuthPageHelper(this.$route);
  }
}
